import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { CasperDashContext } from "../Layout"
import { useWalletService } from "../casper-wallet/wallet-service"

import {
  Link,
  Trans,
  // useI18next,
  // I18nextContext,
} from "gatsby-plugin-react-i18next"

import Logo from "../../assets/casperblockchain-logo.svg"

import { MOBILE_BREAKPOINT } from "../../constans"
import NavbarMobile from "./navbar-mobile"
import WalletError from "./wallet-error"
import WalletPanel from "../molecules/wallet-panel"
import WalletSelect from "./wallet-select"
import NavDropdownMenu from "../atoms/nav-dropdown-menu"

const Navbar = ({
  walletType,
  setWalletType,
  tabActive,
  setTabActive,
  selectedOption,
  setSelectedOption,
}) => {
  const { width } = useWindowDimensions()
  const [menu, setMenu] = useState(false)
  // eslint-disable-next-line
  const [menuLang, setMenuLang] = useState(false)
  const [walletError, setWalletError] = useState("")
  const [isExploreVisible, setIsExploreVisible] = useState(false)
  const [isVerifierVisible, setIsVerifierVisible] = useState(false)
  const ref = useRef(null)
  //const context = React.useContext(I18nextContext)
  //const { languages, changeLanguage } = useI18next()
  const [isSelectVisible, setIsSelectVisible] = useState(false)
  const { isCasperDashInstalled, casperDashPublicKeyContext } =
    React.useContext(CasperDashContext)
  const [casperDashPublicKey] = casperDashPublicKeyContext

  const { activePublicKey } = useWalletService()

  const menuToShow = selection => {
    if (selection === "")
      return (
        <button
          className="btn navbar-btn login-btn"
          to="/stats"
          onClick={() => setIsSelectVisible(true)}
        >
          Log in
        </button>
      )
    if (selection === "CasperDashWallet" && casperDashPublicKey)
      return (
        <WalletPanel
          walletType={"CasperDashWallet"}
          setWalletType={setWalletType}
          tabActive={tabActive}
          setTabActive={setTabActive}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )

    if (selection === "CasperWallet" && activePublicKey)
      return (
        <WalletPanel
          walletType={"CasperWallet"}
          setWalletType={setWalletType}
          tabActive={tabActive}
          setTabActive={setTabActive}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      )

    return (
      <button
        className="btn navbar-btn login-btn"
        onClick={() => setIsSelectVisible(true)}
      >
        Log in
      </button>
    )
  }

  //Sections menu for Mobile version
  const toggleMenu = () => {
    setMenu(!menu)
  }
  const closeMenu = useCallback(() => {
    // added usecallback
    setMenu(false)
  }, [setMenu])

  //Language menu
  // const toggleMenuLang = () => {
  //   setMenuLang(!menuLang)
  // }

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuLang(false)
      }
    }
    document.addEventListener("click", handleClickOutside, true)
    return () => {
      document.removeEventListener("click", handleClickOutside, true)
    }
  }, [closeMenu])

  useEffect(() => {
    const savedWalletType = localStorage.getItem("walletType") || ""
    setWalletType(savedWalletType)

    // Commented out to avoid DashWallet popup (will be removed in the future)

    // const checkCasperDashConnection = async () => {
    //   try {
    //     if (isCasperDashInstalled && !casperDashPublicKey) {
    //       window.casperDashHelper.requestConnection()
    //     }
    //   } catch (error) {
    //     console.error("Error checking Casper connection:", error)
    //   }
    // }

    // if (savedWalletType === "CasperDashWallet") {
    //   checkCasperDashConnection()
    // }
    //eslint-disable-next-line
  }, [isCasperDashInstalled])

  return (
    <div className="navbar">
      {walletError !== "" ? (
        <WalletError
          setWalletError={setWalletError}
          walletError={walletError}
        />
      ) : null}
      <div className="navbar__content main-padding">
        <Link to="/">
          <img src={Logo} alt="casper logo" className="navbar__casper-logo" />
        </Link>
        {width < MOBILE_BREAKPOINT ? (
          <NavbarMobile
            toggleMenu={toggleMenu}
            closeMenu={closeMenu}
            menu={menu}
          />
        ) : (
          <>
            <div className="navbar__links-container">
              <button
                className="btn navbar__invisible-container"
                onMouseEnter={() => setIsExploreVisible(true)}
                onMouseLeave={() => setIsExploreVisible(false)}
              >
                <div className="btn btn--navbar-nohover navbar-btn">
                  <Trans>Explore</Trans>
                  {isExploreVisible && width >= MOBILE_BREAKPOINT ? (
                    <NavDropdownMenu />
                  ) : null}
                </div>
              </button>
              <Link
                className="btn btn--teritary navbar-btn navbar-btn"
                to="/repositories"
              >
                <Trans>Repositories</Trans>
              </Link>
              <Link className="btn btn--teritary navbar-btn" to="/grants">
                <Trans>Grants</Trans>
              </Link>
              <Link className="btn btn--teritary navbar-btn" to="/stats">
                <Trans>Blockchain Stats</Trans>
              </Link>
              <button
                className="btn navbar__invisible-container"
                onMouseEnter={() => setIsVerifierVisible(true)}
                onMouseLeave={() => setIsVerifierVisible(false)}
              >
                {/*
                <div className="btn btn--navbar-nohover navbar-btn">
                  <Trans>Source Code Verifier</Trans>
                  {isVerifierVisible && width >= MOBILE_BREAKPOINT ? (
                    <NavDropdownMenu type="verifier" />
                  ) : null}
                </div>*/}
              </button>
              {menuToShow(walletType)}
              {width > MOBILE_BREAKPOINT ? (
                <>
                  {isSelectVisible ? (
                    <WalletSelect
                      setIsSelectVisible={setIsSelectVisible}
                      setWalletType={setWalletType}
                      setWalletError={setWalletError}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            {/* <div className="navbar__btns">
                {process.env.GATSBY_FEATURE_ACCOUNT === "1" ? (
                  <Link to="/" className="btn btn--sign">
                    <Trans>Log in</Trans>
                  </Link>
                ) : null}
                {process.env.GATSBY_FEATURE_MULTILANG === "1" ? (
                  <div className="navbar__dropdown-lang">
                    <button className="btn btn--lang" onClick={toggleMenuLang}>
                      {context.language}
                      <img
                        src={arrowDown}
                        alt=""
                        className="navbar__dropdown-arrow"
                      />
                    </button>
                    {menuLang ? (
                      <div className="navbar__dropdown-lang-content" ref={ref}>
                        {languages.map(lng => (
                          <button
                            className={"btn btn--lang "}
                            onClick={e => {
                              e.preventDefault()
                              changeLanguage(lng)
                            }}
                          >
                            {lng}
                          </button>
                        ))}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div> */}
          </>
        )}
      </div>
    </div>
  )
}

Navbar.propTypes = {
  walletType: PropTypes.string,
  setWalletType: PropTypes.func.isRequired,
  tabActive: PropTypes.string,
  setTabActive: PropTypes.func.isRequired,
  selectedOption: PropTypes.string,
  setSelectedOption: PropTypes.func.isRequired,
}

export default Navbar
